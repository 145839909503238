import introLeft from "./img/intro-left.png";
import introRight from "./img/intro-right.png";
import about from "./img/about.png";
import tax from "./img/tax.png";
import p1 from "./img/p-1.png";
import p2 from "./img/p-2.png";
import p3 from "./img/p-3.png";

import f1 from "./img/1.png";
import f2 from "./img/2.png";
import f3 from "./img/3.png";
import ss from "./img/CoinGecko@2x.png";
import dt from "./img/dt.png";
import raydium from "./img/Mexc@2x.png";
import ds from "./img/pinksale_logo.png";
import dv from "./img/dexview.png";
import roadmap from "./img/roadmap.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="bg-primary py-8">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col gap-5 lg:flex-row items-center justify-between">
          <h1 className="uppercase text-4xl">
            <span>baby</span>
            <span className="text-gradient">GROKINU</span>
          </h1>
          <ul className="font-title flex gap-10">
            <li>
              <a>Staking (coming soon)</a>
            </li>
            <li>
              <a href="#tokenomics">TOKENOMICS</a>
            </li>
            <li>
              <a href="#roadmap">ROADMAP</a>
            </li>
          </ul>
          <a className="bg-gradient-green w-48 py-3.5 rounded-full border-2 inline-flex items-center justify-center text-center transition-all hover:opacity-75 text-black font-bold">
            BUY NOW
          </a>
        </div>
      </header>

      <main>
        <section className="py-12 bg-[url('./img/intro.png')] bg-cover bg-no-repeat bg-center">
          <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row justify-between items-center gap-8">
            <div className="max-w-lg">
              <div className="max-w-lg">
                <img src={introLeft} alt="" />
              </div>
              <div className="flex flex-col lg:flex-row justify-center gap-5 items-center mt-5">
                <a
                  href="https://t.me/babygrokinu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gradient-purple w-48 py-3.5 rounded-full border-2 inline-flex items-center justify-center text-center transition-all hover:opacity-75 text-black font-bold"
                >
                  TELEGRAM
                </a>
                <a
                  href="https://twitter.com/Babygrok_inu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gradient-yellow w-48 py-3.5 rounded-full border-2 inline-flex items-center justify-center text-center transition-all hover:opacity-75 text-black font-bold"
                >
                  TWITTER
                </a>
              </div>
              {/* <span className="block font-bold text-center mt-12">
                Contract: BABYGROK0000000000000000000000
              </span> */}
            </div>
            <div className="max-w-xl">
              <img src={introRight} alt="" />
            </div>
          </div>
        </section>

        <section className="py-20 lg:py-32">
          <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row justify-between gap-10 items-center">
            <div className="max-w-md">
              <img src={about} alt="" />
            </div>
            <div>
              <h3 className="text-4xl lg:text-5xl text-gradient text-center lg:text-left">
                Welcome to <br /> Baby Grok Inu
              </h3>
              <p className="max-w-xl mt-12 leading-relaxed text-center lg:text-left">
                Baby Grok Inu is a deflationary token. It will be used by Baby
                Grok Inu ecosystem applications. The total supply is
                69,000,000,000 tokens. Baby Grok Inu belongs to everyone in the
                community and is also a necessary key to unlock the future
                chapters of the Baby Grok story.
              </p>
            </div>
          </div>
        </section>

        <section className="py-20 bg-[#250D3B]">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-4xl lg:text-5xl text-gradient">
              Feature Baby Grok Inu
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-20">
              <div className="border-[1px] border-[#DCC614] rounded-2xl px-5 py-8">
                <div className="max-w-xs mx-auto">
                  <img className="rounded-xl" src={f1} alt="" />
                </div>
                <p className="mt-5">
                  4% extracted from the TAX wallet used to Marketing
                </p>
              </div>
              <div className="border-[1px] border-[#DCC614] rounded-2xl px-5 py-8">
                <div className="max-w-xs mx-auto">
                  <img className="rounded-xl" src={f2} alt="" />
                </div>
                <p className="mt-5">
                  Liquidity will be locked, the developer team have no token
                </p>
              </div>
              <div className="border-[1px] border-[#DCC614] rounded-2xl px-5 py-8">
                <div className="max-w-xs mx-auto">
                  <img className="rounded-xl" src={f3} alt="" />
                </div>
                <p className="mt-5">Smart contracts will be audited</p>
              </div>
            </div>
          </div>
        </section>

        <section
          id="tokenomics"
          className="py-20 lg:py-32  bg-[url('./img/tokenomics.png')] bg-cover bg-no-repeat bg-center"
        >
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-3xl lg:text-5xl text-gradient">TOKENOMICS</h3>
            <h5 className="text-xl lg:text-3xl text-gradient mt-16">
              Total Supply : 69,000,000,000
            </h5>
            <h3 className="text-3xl lg:text-4xl my-10">LP : 34%</h3>
            <h3 className="text-3xl lg:text-4xl my-10">Presale : 66%</h3>
            <div className="max-w-[200px] lg:max-w-xs mx-auto">
              <img src={tax} alt="tax" />
            </div>
          </div>
        </section>

        <section id="roadmap" className="py-20 lg:py-32">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-5xl lg:text-6xl text-gradient">ROADMAP</h3>
            <div className="hidden lg:block mt-16">
              <img src={roadmap} alt="" />
            </div>
            <div className="flex flex-col justify-center px-10 lg:px-0 gap-10 lg:flex-row lg:justify-between mt-10">
              <div className="flex flex-col text-left gap-5 font-title">
                <div className="block lg:hidden w-24">
                  <img src={p1} alt="" />
                </div>
                <span>– Come up with ideas</span>
                <span>– Group up teammate</span>
                <span>– Website Launch</span>
                <span>– Presale Launch</span>
                <span>– CoinGecko Listing</span>
                <span>– 5,000 Telegram Members</span>
                <span>– Marketing Campaign</span>
                <span>– CoinMarketCap Listing</span>
                <span>– 2,000 Holders</span>
              </div>
              <div className="flex flex-col text-left gap-5 font-title">
                <div className="block lg:hidden w-24">
                  <img src={p2} alt="" />
                </div>
                <span>– 10,000 Telegram Members</span>
                <span>– Audit second time</span>
                <span>– 5,000 Holders</span>
                <span>– Marketing Campaign</span>
                <span>– Listing Bitmart</span>
                <span>– 7,000 Holders</span>
              </div>
              <div className="flex flex-col text-left gap-5 font-title">
                <div className="block lg:hidden w-24">
                  <img src={p3} alt="" />
                </div>
                <span>– Baby Grok Inu Burn Event</span>
                <span>– More CEX Listings (Gate.io, Mexc)</span>
                <span>– 20,000 Telegram Members</span>
                <span>– 10,000 Holders</span>
                <span>– NFT Marketplace</span>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 lg:py-32 bg-[url('./img/pn.png')] bg-cover bg-no-repeat bg-center">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-4xl lg:text-5xl text-gradient">Partners</h3>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-10 mt-12">
              <a className="max-w-[180px]">
                <img src={ss} alt="" />
              </a>
              <a className="max-w-[180px]">
                <img src={dt} alt="" />
              </a>
              <a className="max-w-[220px]">
                <img src={raydium} alt="" />
              </a>
              <a className="max-w-[200px]">
                <img src={ds} alt="" />
              </a>
              <a className="max-w-[190px]">
                <img src={dv} alt="" />
              </a>
            </div>
          </div>
        </section>

        <section className="pt-16 lg:pt-20 pb-12">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h3 className="text-4xl lg:text-5xl text-gradient">
              Our Community!
            </h3>
            <div className="flex justify-center items-center gap-10 mt-12">
              <a
                href="https://t.me/babygrokinu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="68"
                  height="68"
                  viewBox="0 0 88 88"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 44C0 68.3005 19.6995 88 44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44ZM35.9333 64.1667L36.682 52.9508L36.6813 52.9503L57.0844 34.5382C57.9798 33.7435 56.8888 33.3559 55.7001 34.0768L30.52 49.9626L19.6436 46.5679C17.2948 45.8487 17.2779 44.2347 20.1709 43.0743L62.5539 26.7316C64.4897 25.8528 66.358 27.1965 65.619 30.1593L58.4012 64.1723C57.8972 66.5894 56.4368 67.1674 54.4133 66.051L43.4183 57.9277L38.1333 63.0667C38.1167 63.0829 38.1001 63.099 38.0836 63.1151C37.4925 63.6905 37.0035 64.1667 35.9333 64.1667Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/Babygrok_inu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="68"
                  height="68"
                  viewBox="0 0 88 88"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0ZM42.7745 37.2863L42.6822 35.7638C42.4052 31.8177 44.8366 28.2134 48.6837 26.8152C50.0995 26.318 52.5001 26.2559 54.0697 26.6909C54.6853 26.8773 55.8548 27.4988 56.6858 28.058L58.1939 29.0834L59.8558 28.5552C60.7791 28.2755 62.0102 27.8095 62.5642 27.4988C63.0874 27.2191 63.5491 27.0638 63.5491 27.157C63.5491 27.6852 62.4103 29.4873 61.4562 30.4816C60.1636 31.8798 60.5329 32.0041 63.149 31.072C64.7186 30.5438 64.7494 30.5438 64.4416 31.1341C64.2569 31.4448 63.3029 32.5323 62.2872 33.5266C60.5637 35.2355 60.4714 35.422 60.4714 36.8512C60.4714 39.0573 59.4249 43.6559 58.3785 46.1727C56.4396 50.8955 52.2847 55.7737 48.1297 58.2284C42.2821 61.6773 34.4955 62.5473 27.94 60.5277C25.7548 59.8441 22 58.1041 22 57.7934C22 57.7002 23.1388 57.5759 24.5237 57.5448C27.4168 57.4827 30.3098 56.6748 32.772 55.2455L34.4339 54.2512L32.5258 53.5987C29.8174 52.6666 27.386 50.5227 26.7704 48.503C26.5858 47.8505 26.6473 47.8195 28.3709 47.8195L30.1559 47.7884L28.6479 47.0737C26.8628 46.1727 25.2316 44.6502 24.4314 43.0966C23.8466 41.978 23.108 39.1505 23.3234 38.933C23.385 38.8398 24.0313 39.0262 24.7699 39.2748C26.8936 40.0516 27.1706 39.8652 25.9395 38.5602C23.6312 36.1987 22.9233 32.6877 24.0313 29.363L24.5545 27.8716L26.5858 29.8913C30.7407 33.9616 35.6343 36.3852 41.2357 37.0998L42.7745 37.2863Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
            <p className="mt-12">Copyright 2023 BABY GROK INU</p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
